import React, { useState } from 'react';
import { Modal } from 'dyl-components';

import Selection from './subcomponents/Selection';

const Content = ({ selectionHeader, activeContent, categories, setActiveContent, onClose, ToRender, toRenderProps = {}, hasCancel, hasFirstSelection }) => {
    if (activeContent === 'selection')
        return <Selection
            selectionHeader={selectionHeader}
            categories={categories}
            setActiveContent={setActiveContent}
            close={onClose}
            hasCancel={hasCancel}
            hasFirstSelection={hasFirstSelection} 
        />
    return <ToRender activeContent={activeContent} onClose={onClose} {...toRenderProps} />
}

const SelectionsModal = ({
    selectionHeader = '',
    categories,
    ToRender,
    contentModalSize,

    open,

    toRenderProps = {},
    hasCancel = true,
    hasFirstSelection = true,
    activeContent,
    setActiveContent,
    onClose
}) => {

    return (
        <Modal open={open} onClose={onClose} size={contentModalSize(activeContent)}>
            <Content
                selectionHeader={selectionHeader}
                ToRender={ToRender}
                categories={categories}
                activeContent={activeContent}
                onClose={onClose}
                setActiveContent={setActiveContent}
                toRenderProps={toRenderProps}
                hasCancel={hasCancel}
                hasFirstSelection={hasFirstSelection}
            />
        </Modal>
    );
}

export default SelectionsModal;
